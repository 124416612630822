import { Shoe } from "./interfaces";

export const shoes: Shoe[] = [
  {
    id:1,
    name:"nike_air_force",
    brand:"Fibbl",
    image_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/nike_air_force/shoeIcon.png",
    assets_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/nike_air_force/offsets.json",
    model_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/nike_air_force/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:1,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:2,
    name:"nike_trail",
    brand:"Fibbl",
    image_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/nike_trail/shoeIcon.png",
    assets_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/nike_trail/offsets.json",
    model_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/nike_trail/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:2,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:3,
    name:"airmax_orange",
    brand:"Fibbl",
    image_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/airmax_orange/shoeIcon.png",
    assets_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/airmax_orange/offsets.json",
    model_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/airmax_orange/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:3,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:4,
    name:"airmaxx",
    brand:"Fibbl",
    image_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/airmaxx/shoeIcon.png",
    assets_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/airmaxx/offsets.json",
    model_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/airmaxx/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:4,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:5,
    name:"airmax",
    brand:"Fibbl",
    image_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/airmax/shoeIcon.png",
    assets_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/airmax/offsets.json",
    model_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/airmax/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:5,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:6,
    name:"timberland",
    brand:"Fibbl",
    image_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/timberland/shoeIcon.png",
    assets_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/timberland/offsets.json",
    model_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/timberland/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:6,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:7,
    name:"northface",
    brand:"Fibbl",
    image_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/northface/shoeIcon.png",
    assets_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/northface/offsets.json",
    model_url:"https://vyking-sneaker-studio-preview.s3.amazonaws.com/vyk_clients_9hpq4y/vyk_fibbl_45H%25Kb%24c%C2%A3n%5E%2A%28da/northface/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:7,
    date_created:1590499834,
    last_updated:1590499834
   },
];
